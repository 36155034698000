@import "styles.less";

html {
  scroll-behavior: smooth;
}
.m-l-7{
  margin-left: 7px;
}

button {
  &:focus {
    outline: none;
  }
}

.text-center {
  text-align: center;
}

.bg-twitter {
  background-color: @twitter !important;
}

.bg-danger {
  background-color: @error-color !important;
}

.bg-success {
  background-color: @success-color !important;
}

.bg-info {
  background-color: #53999e !important;
}

.bg-draft {
  background-color: #f4d03f !important;
}

.ant-carousel .slick-dots {
  z-index: 1;
}

.feed_banner {
  img {
    width: 100%;
    height: 234px;
    object-fit: cover;
    display: block;
    border-radius: 12px;
  }

  .manage-post-video {
    height: 234px !important;
    object-fit: cover;
    display: block;
    border-radius: 12px;
  }
}

.cal_view_opt {
  border-radius: 5px;
  overflow: hidden;

  nz-radio-group {
    .ant-radio-button-wrapper {
      background: #f1f8ff;
      border: none;
      padding: 13px 15px;
      border-radius: 0;
      line-height: normal;
      height: auto;

      &.ant-radio-button-wrapper-checked {
        background: rgb(63, 63, 163);
        color: @white;
      }
    }
  }
}

.header_month_nav {
  display: inline-block;
  background: #f1f8ff;
  border-radius: 5px;
  padding: 10px 10px;
  font-weight: normal;
  white-space: initial;

  a {
    font-size: 13px;
    margin: 0 10px;
  }

  p {
    display: inline-block;
    margin: 0 auto;
    width: 160px;
    text-align: center;
  }
}

.event_list {
  .event_list_in {
    width: 100%;
    text-align: left;
    line-height: normal;
    margin: 3px 0;
    padding-right: 5px;

    .event_cal_list {
      display: block;
      background: @primary-color;
      border-radius: 100px;
      padding: 8px 10px 8px 10px;
      font-size: 14px;
      font-weight: 600;
      position: relative;
      text-align: center;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: 1700px) {
        font-size: 12px;
      }

      @media (max-width: 1440px) {
        padding: 6px 10px 6px 10px;
      }

      @media (max-width: 1400px) {
        font-size: 10px;
      }

      @media (max-width: 640px) {
        padding: 0;
        width: 30px;
        height: 30px;
        line-height: 30px;
      }

      > i {
        margin-right: 10px;
        font-size: 20px;
        vertical-align: middle;
        color: #fff;

        @media (max-width: 1700px) {
          margin-right: 5px;
          font-size: 17px;
        }

        @media (max-width: 1440px) {
          font-size: 14px;
        }

        @media (max-width: 640px) {
          margin-right: 0;
        }

        img {
          @media (max-width: 1440px) {
            width: 14px;
            height: 14px;
          }
        }
      }
      > img {
        width: 20px;
        margin-right: 10px;
        vertical-align: middle;
      }

      span {
        @media (max-width: 640px) {
          display: none;
        }

        i {
          margin-left: 5px;
        }
      }

      i.anticon-info-circle {
        right: unset;
        left: 5px;
        top: 6px;
        font-weight: 700;
        font-size: 12px;
        color: #000;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #dad3d3;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #4285f4;
  }
}

/* Emoji Text */
@font-face {
  font-family: "TwemojiMozilla";
  src: local("TwemojiMozilla"),
  url("assets/fonts/TwemojiMozilla.ttf") format("truetype");
  font-weight: normal;
}

textarea,
.emoji_text {
  font-family: "Poppins", "TwemojiMozilla";
}

/* Emoji Text */

/*upload modal*/
.upload-modal {
  max-width: 80%;
  width: 100% !important;
}

.upload-media-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &.media-item-selected {
    .media-list-wrap {
      max-width: calc(100% - 250px);
      padding-right: 10px;
    }

    .post-content-item {
      width: calc(100% / 5);
    }
  }

  .media-selection-wrap {
    width: 100%;
    padding: 10px 0;
  }

  .media-list-wrap {
    max-width: calc(100% - 250px);
    width: 100%;
    padding-right: 20px;
    .empty_gallery.no-data {
      > span {
        width: 100%;
      }
    }
  }

  .media-selected-wrap {
    max-width: 250px;
    width: 100%;
    background: #f6f7ff;
    padding: 10px;

    h2 {
      font-size: 18px;
    }

    h4 {
      margin-bottom: 1px;
      font-size: 13px;
      font-weight: 400;
      color: #000;
      opacity: 0.8;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    app-gallery-image-preview {
      height: 160px;
      display: block;
      width: auto;
      margin-bottom: 20px;
      box-shadow: inset 0 0 0 2px #00224429;
      padding: 3px;

      nz-spin {
        height: 100% !important;

        .ant-spin-container {
          height: 100%;

          video {
            height: 100%;
          }
        }
      }

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.upload-modal {
  vertical-align: top !important;
}

.validation_errors {
  .icon {
    width: 18px !important;
    height: 18px !important;
    border: 1px solid #fff;
    border-radius: 50%;
    vertical-align: text-bottom;
  }
}

.post-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;

  .post-content-item {
    padding: 0 4px;
    width: calc(100% / 5);
    margin-bottom: 10px;
    height: 150px;
    position: relative;

    &.selected {
      ::before {
        position: absolute;
        content: "";
        background-image: url("../src/assets/images/checked.png");
        background-position: center;
        width: 20px;
        height: 20px;
        background-color: #fff;
        right: 10px;
        top: 10px;
        background-size: cover;
        border-radius: 100%;
      }

      app-gallery-image-preview {
        img,
        video {
          border-color: #3542cb !important;
          // padding: 1px;
        }
      }
    }
  }

  app-gallery-image-preview {
    display: block;
    width: 100%;
    height: 100%;

    nz-spin {
      height: 100% !important;

      .ant-spin-container {
        height: 100%;

        video {
          height: 100%;
        }
      }
    }

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 4px solid #d0c9ff !important;
      cursor: pointer;
      border-radius: 5px;
    }
  }
}

.upload_img_wrp {
  .upload_img_input {
    margin-left: 15px;
  }

  .upload_img {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    .upload_img_in {
      display: flex;
      position: relative;
      margin-right: 15px;
      margin-bottom: 15px;

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        background: rgb(0 0 0 / 30%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        // opacity: 0;
        transition: all 0.2s linear;
        border-radius: 5px;
      }



      .upload_img_action {
        position: absolute;
        z-index: 1;
        color: #fff;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        display: flex;
        transition: all 0.2s linear;

        i {
          width: 25px;
          height: 25px;
          text-align: center;
          margin: 0 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.25rem;
          background: #ffffff;
          color: #423fd1;
          cursor: pointer;
          transition: all 0.2s linear;

          &:hover {
            background: #423fd1;
            color: #fff;
          }
        }
      }

      .ant-spin-container {
        height: 102px;
        width: 102px;
        margin-top: 0;
      }

      video {
        width: 102px;
        height: 102px;
        // width: 200px;
        // height: 200px;
        border-radius: 5px;
        border: 1px dashed @normal-color;
        display: block;
      }

      .icon {
        position: absolute;
        top: -3px;
        right: -10px;
        color: @white;
        cursor: pointer;
        z-index: 1;
        background: @red;
        border-radius: 100%;
        font-size: 16px;
      }
    }
  }
}

.upload_img {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  .upload_img_in {
    display: flex;
    position: relative;
    margin-right: 15px;
    margin-bottom: 15px;

    .btn_hash_tag {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      img {
        width: 35px;
      }
    }

    .ant-spin-container {
      height: 102px;
      width: 102px;
    }

    // video {
    //   width: 200px;
    //   height: 200px;
    //   border-radius: 5px;
    //   border: 1px dashed @normal-color;
    //   display: block;
    // }

    // .post-image{
    //   width: 200px;
    //   height: 200px;
    // }

    .icon {
      position: absolute;
      top: -3px;
      right: -10px;
      color: @white;
      cursor: pointer;
      z-index: 1;
      background: @red;
      border-radius: 100%;
      font-size: 16px;
    }
  }
}

.upload_img_wrp {
  .upload_video {
    width: 100%;

    .upload_video_in {
      width: 150px;
      height: 150px;
      position: relative;

      &::before{
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        transition: all 0.2s linear;
        border-radius: 5px;
      }

      .btn_hash_tag {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
        img {
          width: 35px;
        }
      }
      .icon {
        cursor: pointer;
        width: 20px;
        height: 20px;
        background: #eb3343;
        border-radius: 100%;
        top: -6px;
        right: -6px;
        line-height: 27px;
        font-size: 14px;
        text-align: center;
        position: absolute;
        z-index: 1;
      }

      i {
        position: absolute;
        top: -7px;
        right: -7px;
        color: @white;
        cursor: pointer;
        z-index: 1;
        background: @red;
        border-radius: 100%;
        font-size: 16px;
      }
    }
  }
}

.account-list-image {
  width: 14%;
}

/*create post*/
.yt_thumbnail {
  margin-top: 15px;

  h3 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0px;
    font-weight: 600;
    margin: 0 0 20px 0;
    color: #262c6c;

    @media (max-width: 1199px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

/*create post*/

.error-color {
  color: @error-color;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  // color: #3749cc;
  background-color: #3749cc38;
  font-weight: 500;

  .ant-select-item-option-state {
    color: #3749cc;
  }
}

.ant-select-item-option {
  align-items: center;
}

.pre_sec {
  &.post_instagram {
    .img_sec {
      .ant-carousel {
        padding-bottom: 15px;

        .slick-list {
          margin-bottom: 0 !important;
        }

        .slick-dots {
          bottom: -14px !important;
          margin: 0;

          li {
            width: 6px !important;
            height: 6px !important;
            border-radius: 100%;

            &.slick-active {
              button {
                background: rgb(60, 147, 237) !important;
              }
            }

            button {
              width: 6px !important;
              height: 6px !important;
              border-radius: 100%;
              background: rgb(167, 165, 166) !important;
              opacity: 1;
            }
          }
        }
      }

      .slick-slide {
        app-image-preview {
          img {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
      }
    }

    .des_sec {
      padding: 0 !important;
    }
  }
}

.create_content_right {
  .slick-list {
    height: auto !important;

    .slick-track {
      height: auto !important;

      .slick-slide {
        height: auto !important;
      }
    }
  }
}

.create_content {
  .create_content_right {
    .slick-slider {
      .slick-list {
        margin-bottom: 40px;
      }

      > .slick-dots.slick-dots-bottom {
        bottom: -35px;
        margin: 0;

        li {
          width: auto;
          height: 6px;

          &.slick-active {
            button {
              width: 45px;
              background-color: #3851f7;
            }
          }

          button {
            padding: 0;
            border: 0;
            font-size: 0;
            height: 6px;
            width: 30px;
            background: #e5e5e5;
            border-radius: 100px;
            transition: all 0.2s linear;
            opacity: 1;
          }
        }
      }
    }
  }
}

.create_content .create_content_right .insta_story_wrapper .slick-slider > .slick-dots.slick-dots-bottom{
  bottom: 30px;

}

.create_content .create_content_right .insta_story_wrapper .slick-slider > .slick-dots.slick-dots-bottom li button{
  width: 18px;
}

.repeat {
  .ant-select {
    width: 190px;
    height: 30px;
  }

  .ant-picker-large {
    padding: 0 5px;
    height: 30px;
  }
}

.repeat-data {
  max-height: 300px;
  overflow: auto;
  padding: 0 24px;

  .ant-picker-large {
    width: 95%;
  }

  .delete-repeat {
    height: 30px;
    width: 30px;
    margin: 4px 0;
  }
}

.margin-bottom-1 {
  margin-bottom: 5px !important;
}

.margin-top-3 {
  margin-top: 15px !important;
}

.margin-bottom-4 {
  margin-bottom: 20px !important;
}

.margin-left-2 {
  margin-left: 10px !important;
}

.cal_event_body {
  .pre_sec {
    margin-bottom: 20px;
  }
}

//--Start theme tabbing
.theme_tabbing {
  .ant-tabs {
    border: 1px solid @dark-grey;
    border-radius: 5px;
    padding: 24px;

    .ant-tabs-nav {
      margin-bottom: 24px;

      &::before {
        border-bottom: 2px solid @dark-grey;
        bottom: 0;
      }

      .ant-tabs-tab {
        border: none;
        background: transparent;
        padding: 0 0 15px 0;
        margin-right: 20px !important;

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn a {
            color: @primary-color;
          }
        }

        .ant-tabs-tab-btn a {
          font-weight: 500;
          font-size: 16px;
          color: @light-grey;
          line-height: normal;
        }
      }

      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-ink-bar {
            visibility: visible;
            bottom: 0;
          }
        }
      }
    }
  }
}

.preview_post_modal_wrp {
  .ant-modal-content {
    .ant-modal-close {
      .ant-modal-close-x {
        color: #423fd1;
        font-size: 20px;
      }
    }
    .ant-modal-body {
      padding-top: 18px;
    }
  }
}

.upload-modal.display_none .ant-modal-footer {
  display: none;
}

.ant-modal-mask,
.ant-drawer-mask {
  background-color: rgba(0, 0, 0, 0.16) !important;
}

.ant-modal-content {
  border-radius: 12px;
  overflow: hidden;
}

.ant-message {
  top: 90px !important;
}

.cs_twiiter_profile_sec {
  .card.cs_twiiter_profile_sec {
    .ant-card-body {
      padding: 0px 0px;
    }
  }

  .profile_bg_wrapper {
    .account-list-image {
      border-radius: 0px;
      height: 220px;
      object-fit: cover;
    }
  }
}

.cs_twiiter_profile_sec .profile_bg_wrapper {
  height: 220px;
}

.cs_twiiter_profile_sec {
  .profile_contant {
    align-items: flex-start;
  }
}

@media (max-width: 1199px) {
  .ant-col-12.cs_twiiter_profile_sec,
  .ant-col-12.cs_twitter_column {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 575px) {
  .profile_contant {
    margin-top: -96px;
    padding: 0px 10px 10px 10px;
  }

  .cs_twiiter_profile_sec {
    .profile_bg_wrapper {
      .account-list-image {
        height: 200px;
      }
    }
  }

  .profile_contant app-image-preview .account-list-image {
    width: 120px;
    height: 120px;
    border: 4px solid #fff;
  }

  .profile_contant {
    margin-top: -82px;
    padding: 0px 10px 15px 10px;
  }

  .profile_cs_text_contant {
    .name {
      font-size: 18px;
    }
  }

  .profile_cs_text_contant {
    margin-top: -10px;
  }

  .profile_cs_text_contant {
    .username_cs {
      font-size: 14px;
    }
  }

  .profile_cs_text_contant {
    .join_date {
      font-size: 14px;
    }
  }

  .profile_cs_text_contant {
    .join_date {
      img {
        width: 19px;
        position: relative;
        top: -1px;
      }
    }
  }

  .profile_cs_text_contant .following_followers_sec .label,
  .profile_cs_text_contant .following_followers_sec .counter {
    color: #6884a0;
    font-size: 14px;
    display: inline-block;
  }

  .preview_post_wrp {
    .preview_post_header {
      .preview_post_hdr_left {
        .title_wrp {
          padding-left: 0px;
        }
      }
    }
  }
}

.cs_twit_sec .card_2column:nth-child(3) {
  padding-left: 15px;
}

.cs_twit_sec {
  max-height: calc(100vh - 150px);
  overflow: auto;
}

.profile_contant {
  margin-top: -60px;
  padding: 0px 30px 30px 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  @media (max-width: 480px) {
    display: block;
  }

  .profile_cs_text_contant {
    padding-bottom: 8px;
    padding-left: 10px;
  }
}

.profile_contant .profile_cs_text_contant {
  padding-top: 85px;
}

.profile_cs_text_contant {
  .name {
    font-size: 20px;
    color: #000;
    margin-bottom: 0px;
    line-height: normal;
  }
}

.profile_cs_text_contant {
  .username_cs {
    color: #6884a0;
    font-size: 16px;
    margin-bottom: 9px;
  }
}

.profile_cs_text_contant {
  .join_date {
    color: #6884a0;
    font-size: 16px;
    margin-bottom: 9px;
  }
}

.profile_cs_text_contant {
  .join_date {
    img {
      width: 21px;
      position: relative;
      top: -2px;
    }
  }
}

.profile_cs_text_contant {
  .following_followers_sec {
    display: flex;
    flex-wrap: wrap;
  }
}

.profile_cs_text_contant {
  .following_followers_sec {
    .following {
      margin-right: 15px;
    }
  }
}

.profile_cs_text_contant .following_followers_sec .label,
.profile_cs_text_contant .following_followers_sec .counter {
  color: #6884a0;
  font-size: 16px;
  display: inline-block;
}

.profile_cs_text_contant {
  .following_followers_sec {
    .counter {
      color: #000;
      margin-right: 4px;
    }
  }
}

.cdk-global-scrollblock .cdk-overlay-container {
  z-index: 99;
}

.table_default {
  .ant-table-content {
    table {
      .ant-table-thead {
        tr {
          th {
            background: transparent;
            border-color: #e5e9f2;
            padding: 0px 10px 6px 10px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #232c89;

            @media (max-width: 1440px) {
              font-size: 14px;
              line-height: 22px;
            }

            &.org_action {
              text-align: right;
            }

            &::before {
              display: none;
            }
          }
        }
      }

      .ant-table-tbody {
        tr {
          &.remove_hover {
            &:hover {
              td {
                background: transparent !important;
              }
            }
          }

          &:hover {
            td {
              background: #f6f7ff !important;
            }
          }

          td {
            padding: 28px 10px 16px 10px !important;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #1d203cde;

            @media (max-width: 1440px) {
              font-size: 14px;
              line-height: 22px;
            }

            .btn_wrap {
              display: flex;
              white-space: nowrap;
              justify-content: flex-end;

              .btn {
                background: #f6f7ff;
                border-radius: 100px;
                border: none;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #494adf;
                cursor: pointer;
                transition: all 0.2s linear;
                // padding: 11px 26px;
                padding: 13px 26px 11px 26px;
                margin-right: 12px;
                height: auto;

                @media (max-width: 1440px) {
                  font-size: 12px;
                  line-height: 18px;
                  padding: 8px 20px;
                }

                @media (max-width: 767px) {
                  width: 48px;
                  height: 48px;
                  border-radius: 100%;
                  padding: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                i{
                  vertical-align: middle;
                }

                span {
                  margin: 0;
                  @media (max-width: 767px) {
                    display: none;
                  }
                }

                &:last-child {
                  margin: 0;
                }

                &:hover {
                  background: #3a91cc;
                  color: #fff;

                  svg {
                    path {
                      stroke: #fff;
                    }
                  }
                }

                &.edit-account {
                  background: #494adf;
                  color: #f5f6f2;

                  &:hover {
                    background: #3a91cc;
                    color: #fff;
                  }
                }

                svg {
                  margin-right: 10px;
                  vertical-align: middle;

                  @media (max-width: 1440px) {
                    vertical-align: middle;
                    width: 15px;
                  }

                  @media (max-width: 767px) {
                    margin-right: 0;
                    width: 25px;
                  }

                  path {
                    transition: all 0.2s linear;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//--
.auth_selection_modal {
  width: 850px !important;
  top: 30px;

  .ant-modal-header {
    padding: 30px 30px 20px 30px;

    .ant-modal-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #262c6c;
    }
  }

  .ant-modal-close {
    display:none;
    // top: 15px;

    // .ant-modal-close-x {
    //   color: #262c6c;
    // }
  }

  .ant-modal-body {
    padding: 30px;

    .account_list {
      padding-right: 20px;
      padding-bottom: 7px;

      .account_list_in {
        margin: 0 0 40px 0 !important;
        padding: 0 !important;

        .ant-checkbox-wrapper {
          display: block !important;
          position: relative;
          // margin: 0 0 13px 0;
          // border-bottom: 1px solid #e5e9f2;
          // padding-bottom: 13px;

          &.disabled {
            opacity: 0.5;
          }

          &.ant-checkbox-wrapper-checked {
            .account_list_info {
              &:after {
                background: #423fd1;
                border-color: #423fd1;
              }

              // &::before {
              //   content: "";
              //   position: absolute;
              //   width: 6px;
              //   height: 10px;
              //   border: solid #ffffff;
              //   border-width: 0 2px 2px 0;
              //   right: 7px;
              //   top: 48%;
              //   transform: translateY(-48%) rotate(40deg);
              //   z-index: 1;
              // }

              .img_sec {
                .icon {
                  right: -5px !important;
                  bottom: -5px;
                  top: initial !important;
                  border: none !important;
                  width: 20px !important;
                  height: 20px !important;
                }
              }
            }
          }

          &::after {
            display: none;
          }

          .ant-checkbox {
            display: none;
          }

          > span {
            padding: 0;

            .account_list_info {
              position: relative;
              padding-right: 30px;

              &::after {
                content: "";
                border: 2px solid rgba(29, 32, 60, 0.67);
                width: 20px;
                height: 20px;
                display: inline-block;
                border-radius: 100%;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              }

              .img_sec {
                width: 48px !important;
                height: 48px !important;

                .icon {
                  right: -5px !important;
                  bottom: -5px;
                  top: initial !important;
                  border: none !important;
                  width: 20px !important;
                  height: 20px !important;
                }
              }

              .name_wrp {
                width: calc(100% - 48px) !important;

                span {
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                }
              }
            }
          }
        }
      }
    }

    .filter_select_all_account {
      margin: 0 0 13px 0;
      border-bottom: 1px solid #e5e9f2;
      padding-bottom: 13px;
      display: block;
      padding-left: 58px;
      position: relative;
      margin-right: 20px;

      &.ant-checkbox-wrapper-checked {
        span {
          &::before {
            background: #676df4;
          }
        }
      }

      .ant-checkbox {
        display: none;
      }

      span {
        padding: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 48px;
        height: 48px;
        display: inline-block;
        vertical-align: middle;

        &::before {
          content: "";
          width: 48px;
          height: 48px;
          background: #b3b3b3;
          position: absolute;
          border-radius: 8px;
          top: 0;
          left: 0;
        }

        &::after {
          content: "";
          width: 48px;
          height: 48px;
          position: absolute;
          border-radius: 8px;
          top: 0;
          left: 0;
          background-image: url("assets/images/select_all_account.png");
          background-size: 20px;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .ant-modal-footer {
    box-shadow: 0px 4px 8px 3px rgb(35 44 137 / 15%);
    padding: 15px;
    margin: 15px -15px 0px -15px;
    border-radius: 12px;
    // display: flex;

    .auth_selection_header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      strong {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #1d203c;
      }
      a {
        color: rgba(29, 32, 60, 0.67);
      }
    }
    .auth_selection_action {
      display: flex;
    }

    .ant-btn {
      width: 100%;
      background: #f6f7ff;
      border-radius: 100px;
      border: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #494adf;
      height: 48px;
      cursor: pointer;
      transition: all 0.2s linear;

      &:hover {
        background-color: #3a91cc;
        color: #fff;
      }

      &[disabled]{
        background: #babad7;
        color: #fff;
        cursor: not-allowed;

        &:hover {
          background: #babad7;
        }
      }

      &.ant-btn-primary {
        background: #423fd1;
        color: #fff;

        &[disabled]{
          background: #babad7;
          cursor: not-allowed;

          &:hover {
            background: #babad7;
          }
        }

        &:hover {
          background-color: #3a91cc;
          color: #fff;
        }
      }
    }
  }
}

.auth_waiting {
  border: 1px solid rgba(35, 44, 137, 0.12);
  border-radius: 24px;
  padding: 150px 30px;

  .auth_waiting_in {
    text-align: center;

    img {
      max-width: 500px;
      width: 100%;
      margin-bottom: 20px;
    }

    h3 {
      margin: 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #2a2626;
    }

    .loader_wrp {
      text-align: center;
      display: inline-flex;
      justify-content: center;
      width: 50px;
      height: 50px;
    }

    .loader {
      position: absolute;
      width: 50px;
      height: 50px;
      z-index: 9;
      border-radius: 100%;
      margin: 30px auto 0 auto;
      pointer-events: none;

      &::before {
        position: absolute;
        content: "";
        width: 50px;
        height: 50px;
        border: 4px solid #262c6c;
        border-radius: 100px;
        border-bottom-color: transparent;
        transition: all 0.5s ease-in;
        animation-name: loading;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }

    @keyframes loading {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
}

.disabled_while_loading {
  pointer-events: none;
  opacity: 0.5;
}

.apexcharts-toolbar,
.apexcharts-tooltip {
  z-index: 8 !important;
}

.ant-upload {
  &.ant-upload-select-picture-card {
    border: 2px dashed #7fc0eb;
    border-radius: 12px;
    background: #e6f4fe;

    &:hover {
      border-color: #7fc0eb;
    }

    .ant-upload {
      flex-direction: column;

      i {
        font-size: 20px;
      }

      .ant-upload-text {
        font-weight: 600;
        margin-top: 12px;
      }
    }
  }
}

.loading-text {
  top: 50% !important;
}

.ant-notification-notice {
  background: #676df4ed;
  border-radius: 5px;

  * {
    color: #fff;
  }

  .ant-notification-notice-message {
    font-weight: 600;
  }
}


//--
.ant-notification-success {
  background: #F1FAF7;
  border-radius: 5px;
  border: 2px solid #31C38C;
  color: #31C38C;
  & *{
    color: #fff;
  }
  .ant-notification-notice-close * {
    color: #31C38C;
  }
  .anticon.ant-notification-notice-icon-success {
    width: 40px;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(49 195 140);
    border-radius: 100%;
    left: 23px;
    margin: 0;
    &::after{
      content: '';
      width: 70px;
      height: 70px;
      position: absolute;
      top: -15px;
      bottom: 0;
      left: -15px;
      right: 0;
      background: rgb(49 195 140);
      border-radius: 100%;
      z-index: -1;
      animation: growAndFade 1.5s infinite ease-out;
      // animation-delay: 1s;
    }
    &::before{
      content: '';
      display: block;
      width: 70px;
      height: 70px;
      position: absolute;
      top: -15px;
      bottom: 0;
      left: -15px;
      right: 0;
      background: rgb(49 195 140);
      border-radius: 100%;
      z-index: -1;
      animation: growAndFade 1.5s infinite ease-out;
      // animation-delay: 2s;
    }
  }
  .ant-notification-notice * {
    color: #31C38C;
  }
  .ant-notification-notice-message, .ant-notification-notice-description {
    margin-left: 60px;
    color: #397963;
  }
}

@keyframes growAndFade {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.ant-notification-danger {
  background: #FDF1F1;
  border-radius: 5px;
  border: 2px solid #E8494B;
  color: #E8494B;
  & *{
    color: #fff;
  }
  .ant-notification-notice-close * {
    color: #E8494B;
  }
  .anticon.ant-notification-notice-icon-error {
    width: 40px;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(232, 73, 76);
    border-radius: 100%;
    left: 23px;
    margin: 0;
    &::after{
      content: '';
      width: 70px;
      height: 70px;
      position: absolute;
      top: -15px;
      bottom: 0;
      left: -15px;
      right: 0;
      background: #E8494B;
      border-radius: 100%;
      z-index: -1;
      animation: growAndFade 1.5s infinite ease-out;
      // animation-delay: 1s;
    }
    &::before{
      content: '';
      display: block;
      width: 70px;
      height: 70px;
      position: absolute;
      top: -15px;
      bottom: 0;
      left: -15px;
      right: 0;
      background: #E8494B;
      border-radius: 100%;
      z-index: -1;
      animation: growAndFade 1.5s infinite ease-out;
      // animation-delay: 2s;
    }
  }
  .ant-notification-notice * {
    color: #E8494B;
  }
  .ant-notification-notice-message, .ant-notification-notice-description {
    margin-left: 60px;
    color: #E8494B;
  }
}

.ant-notification-info {
  background: #FEFDEB;
  border-radius: 5px;
  border: 2px solid #E1A00F;
  color: #E1A00F;
  & *{
    color: #fff;
  }
  .ant-notification-notice-close * {
    color: #E1A00F;
  }
  .anticon.ant-notification-notice-icon-info {
    width: 40px;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e19e0f;
    border-radius: 100%;
    left: 23px;
    margin: 0;
    &::after{
      content: '';
      width: 70px;
      height: 70px;
      position: absolute;
      top: -15px;
      bottom: 0;
      left: -15px;
      right: 0;
      background: #E1A00F;
      border-radius: 100%;
      z-index: -1;
      animation: growAndFade 1.5s infinite ease-out;
      // animation-delay: 1s;
    }
    &::before{
      content: '';
      display: block;
      width: 70px;
      height: 70px;
      position: absolute;
      top: -15px;
      bottom: 0;
      left: -15px;
      right: 0;
      background: #E1A00F;
      border-radius: 100%;
      z-index: -1;
      animation: growAndFade 1.5s infinite ease-out;
      // animation-delay: 2s;
    }
  }
  .ant-notification-notice * {
    color: #E1A00F;
  }
  .ant-notification-notice-message, .ant-notification-notice-description {
    margin-left: 60px;
    color: #E1A00F;
  }
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// *:focus-visible {
//   outline: 1px solid #000000 !important;
//   border-radius: 0.25rem;
// }

// input:focus-visible {
//   outline: none !important;
// }

.ant-checkbox-wrapper.cdk-focused .ant-checkbox-inner {
  box-shadow: 0 0 0 0.2rem rgb(35 44 137 / 25%);
}

.ant-btn:focus-visible {
  box-shadow: 0 0 0 0.2rem rgb(35 44 137 / 34%);
  outline: none !important;
}

input::-webkit-credentials-auto-fill-button {
  opacity: 0;
}
cdk-virtual-scroll-viewport {
  // height: fit-content !important;
  // max-height: initial !important;
  // min-height: 200px;
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

//--
.ant-pagination {
  .ant-pagination-item {
    min-width: 40px;
    border-radius: 50px;
    height: 40px;
    line-height: 40px;
    &:hover {
      background: #3d53da;
      border-color: #3d53da;
      a {
        color: #fff;
      }
    }
  }
  .ant-pagination-item-active {
    border-color: #3d53da;
    background: #3d53da;
    &:hover {
      border-color: #3d53da;
      opacity: 0.8;
      a {
        color: #fff;
      }
    }
    a {
      color: #fff;
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    min-width: 40px;
    height: 40px;
  }
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    border-radius: 50px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 50px;
    padding: 6px 20px 0 20px;
    text-transform: capitalize;
    min-height: 40px;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 50px;
  }
  .ant-pagination-prev .ant-pagination-item-link:hover,
  .ant-pagination-next .ant-pagination-item-link:hover {
    background: #3d53da;
    border-color: #3d53da;
    color: #fff;
  }
  .ant-pagination-disabled:hover button,
  .ant-pagination-disabled:hover button {
    background-color: transparent !important;
    border-color: #d9d9d9 !important;
    color: #d9d9d9 !important;
  }

  .ant-pagination-disabled:hover button, .ant-pagination-disabled:hover button {background-color: transparent !important;border-color: #d9d9d9 !important;color:#d9d9d9 !important}
}

// .ant-picker-panel-container {
//   border-radius: 12px;
// }
// .ant-picker-cell-in-view {
//   color: #232C89;
// }
// .ant-picker-header button {
//   color: #232C89;
//   font-weight: 500;
// }
// .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after{
//   height: 40px;
// }
// .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
//   background: #F6F7FF;
// }
// .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before{
//   background: #423FD1;
// }
// .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after, .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after{
//   background: #423FD1;
// }
// .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
//   background: #423FD1;
// }
// .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before{
//   background: #423FD1;
// }
// .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
//   border-radius: 100%;
//   border: 1px solid #423FD1;
// }
// .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover .ant-picker-cell-inner,
// .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover .ant-picker-cell-inner,
// .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover .ant-picker-cell-inner,
// .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start .ant-picker-cell-inner,
// .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end .ant-picker-cell-inner,
// .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner,
// .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner{
//   color: #fff;
// }
// .ant-picker-date-range-wrapper .ant-picker-panel-container .ant-picker-panels .ant-picker-panel .ant-picker-date-panel{
//   width: 300px;
// }
// .ant-picker-date-range-wrapper .ant-picker-panel-container .ant-picker-panels .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content thead tr th {
//   width: 40px;
//   height: 40px;
//   border-radius: 100%;
//   font-weight: 600;
//   color: #232C89;
//   font-size: 13px;
// }
// .ant-picker-cell .ant-picker-cell-inner {
//   font-size: 13px;
//   min-width: 40px;
//   height: 40px;
//   line-height: 45px;
//   border-radius: 100% !important;
// }
// .ant-picker-cell::before {
//   height: 40px;
// }
// .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .range-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .range-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .range-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, .range-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, .range-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, .range-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after{
//   height: 40px;
// }
// .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after{
//   height: 40px;
// }

// .ant-picker-date-panel .ant-picker-content {
//   width: 100%;
// }
// .ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel, .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-date-panel, .ant-picker-time-panel{
//   width: 320px;
// }

.ant-picker-panel-container {
  border-radius: 12px;
}
.ant-picker-cell-in-view {
  color: #232c89;
}
.ant-picker-header {
  button {
    color: #232c89;
    font-weight: 500;
  }
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start {
  &:not(.ant-picker-cell-in-range) {
    &:not(.ant-picker-cell-range-start) {
      &:not(.ant-picker-cell-range-end) {
        &::after {
          height: 40px;
          height: 40px;
          height: 40px;
        }
      }
    }
  }
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end {
  &:not(.ant-picker-cell-in-range) {
    &:not(.ant-picker-cell-range-start) {
      &:not(.ant-picker-cell-range-end) {
        &::after {
          height: 40px;
          height: 40px;
        }
      }
    }
  }
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single {
  &::after {
    height: 40px;
    height: 40px;
  }
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover {
  &::after {
    height: 40px;
    height: 40px;
  }
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover {
  &::after {
    height: 40px;
    height: 40px;
  }
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single {
  &::after {
    height: 40px;
    height: 40px;
  }
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover {
  &:not(.ant-picker-cell-in-range) {
    &::after {
      height: 40px;
      height: 40px;
    }
  }
}
.ant-picker-cell-in-view.ant-picker-cell-in-range {
  &::before {
    background: #f6f7ff;
  }
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover {
  &::before {
    background: #423fd1;
  }
  .ant-picker-cell-inner {
    color: #ffffff;
  }
}
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover {
  &::before {
    background: #423fd1;
  }
  .ant-picker-cell-inner {
    color: #ffffff;
  }
}
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover {
  &::before {
    background: #423fd1;
  }
  .ant-picker-cell-inner {
    color: #ffffff;
  }
}
.ant-picker-cell-in-view.ant-picker-cell-range-start {
  &:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start {
    &::before {
      background: #423fd1;
    }
    .ant-picker-cell-inner {
      color: #ffffff;
    }
  }
  .ant-picker-cell-inner {
    background: #423fd1;
  }
  &:not(.ant-picker-cell-range-start-single) {
    &::before {
      background: #423fd1;
    }
  }
}
.ant-picker-cell-in-view.ant-picker-cell-range-end {
  &:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end {
    &::before {
      background: #423fd1;
    }
    .ant-picker-cell-inner {
      color: #ffffff;
    }
  }
  .ant-picker-cell-inner {
    background: #423fd1;
  }
  &:not(.ant-picker-cell-range-end-single) {
    &::before {
      background: #423fd1;
    }
  }
}
.ant-picker-panel {
  &:not(.ant-picker-date-panel) {
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start {
      &::before {
        background: #423fd1;
      }
      .ant-picker-cell-inner {
        color: #ffffff;
      }
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end {
      &::before {
        background: #423fd1;
      }
      .ant-picker-cell-inner {
        color: #ffffff;
      }
    }
  }
}
.ant-picker-date-panel {
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start {
    .ant-picker-cell-inner {
      &::after {
        background: #423fd1;
      }
    }
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end {
    .ant-picker-cell-inner {
      &::after {
        background: #423fd1;
      }
    }
  }
  .ant-picker-content {
    width: 100%;
  }
  width: 320px;
}
.ant-picker-cell-in-view.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background: #423fd1;
  }
}
.ant-picker-cell-in-view.ant-picker-cell-today {
  .ant-picker-cell-inner {
    &::before {
      border-radius: 100%;
      border: 1px solid #423fd1;
    }
  }
}
.ant-picker-date-range-wrapper {
  .ant-picker-panel-container {
    .ant-picker-panels {
      .ant-picker-panel {
        .ant-picker-date-panel {
          width: 300px;
          .ant-picker-body {
            .ant-picker-content {
              thead {
                tr {
                  th {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    font-weight: 600;
                    color: #232c89;
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.ant-picker-cell {
  .ant-picker-cell-inner {
    font-size: 13px;
    min-width: 40px;
    height: 40px;
    line-height: 45px;
    border-radius: 100% !important;
  }
  &::before {
    height: 40px;
  }
}
.range-picker {
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end {
    &:not(.ant-picker-cell-in-range) {
      &:not(.ant-picker-cell-range-start) {
        &:not(.ant-picker-cell-range-end) {
          &::after {
            height: 40px;
          }
        }
      }
    }
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single {
    &::after {
      height: 40px;
    }
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover {
    &::after {
      height: 40px;
    }
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover {
    &::after {
      height: 40px;
    }
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single {
    &::after {
      height: 40px;
    }
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover {
    &:not(.ant-picker-cell-in-range) {
      &::after {
        height: 40px;
      }
    }
  }
}
.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  width: 320px;
}
.ant-picker-content th {
  font-weight: 600;
  color: #232c89;
  font-size: 13px;
}






::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: @secondaryColor;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: @secondaryColor;
}


.range_picker_main{
  .ant-picker-time-panel{
    display: none;
  }
}

// @media (min-width:640px){
//   .user_eng_chart{
//     .apexcharts-xaxis-label{
//       transform: translateX(20px)!important;
//     }
//   }
// }
@media (max-width:640px){
  .apexcharts-xaxis-texts-g{
    transform: translate(5px, 0);
  }
}
// .user_eng_chart {
//   padding: 0 20px;
// }


.no_account_available{
  font-size: 20px;
  text-align: center;
  padding: 5px;
  margin-bottom: 5px;
  font-weight: 600;
  color: #262c6c;
}

.openNzSelect .ant-modal-wrap,
.openNzSelect .permissionModal .ant-modal-body app-member-permission .permision_modal_body .permision_modal_body_in,
.openNzSelect .first_step .team-invite-drawer-item .team-invite-drawer-content .team-invite-drawer-content-in {
  overflow: hidden;
}
.openNzSelect .campaign_wrapper {
  overflow-y: hidden !important;
}

.account_connect_modal {
  width: 1100px !important;
  @media (max-width:640px) {
    width: 100% !important;
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    max-width: none;
    padding: 0;
  }
  .ant-modal-content {
    @media (max-width:640px) {
      border-radius: 0;
      .ant-modal-body {
        height: calc(100vh - 64px);
        overflow-y: scroll;
      }
    }
    .ant-modal-header {
      padding-top: 25px;
      .ant-modal-title {
        font-size: 22px;
        font-weight: 600;
        color: #262C6C;
      }
    }
    .ant-modal-close {
      top: 25px;
      right: 24px;
      .ant-modal-close-x {
        width: 18px;
        height: 18px;
        line-height: 0;
        font-size: 0;
        i{
          font-size: 0;
          width: 100%;
          height: 100%;
          background-image: url('./assets/images/close_icon.svg');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }
}

.org_optn_list_wrap  .ant-radio-checked::after {
  border: none!important;
}

//--
.shepherd-has-title{
  .shepherd-content{
    padding: 20px;
    border: 2px solid #494ADF;
    .shepherd-text {
      color: #000;
      padding: 0 0px 20px 0;
      font-size: 14px;
    }
    .shepherd-header {
      background: transparent !important;
      padding: 0 0 10px 0 !important;
      .shepherd-title {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}

.shepherd-arrow, .shepherd-arrow:before {
  z-index: 1 !important;
}
.shepherd-element[data-popper-placement^=top]>.shepherd-arrow {
  bottom: -6px !important;
}
.shepherd-element[data-popper-placement^=bottom]>.shepherd-arrow {
  top: -6px !important;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible{
  opacity: .3 !important;
}
.shepherd-element.shepherd-has-title[data-popper-placement^=bottom] > .shepherd-arrow:before {
  background: transparent !important;
  width: 0px;
  height: 0px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #494ADF;
  transform: none;
  top: -4px;
}
.shepherd-element.shepherd-has-title[data-popper-placement^=top] .shepherd-arrow:before {
  background: transparent !important;
  width: 0px;
  height: 0px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #494ADF;
  transform: none;
  top: 8px;
}



.shepherd-footer{
  .shepherd-button{
    &.shepherd-button-secondary{
      background: #494ADF;
      border-radius: 100px;
      font-size: 14px;
      color: white;
      font-weight: 600;
      padding: 8px 20px 5px 20px;
      &.btn_back{
        color: #494ADF;
        background: transparent;
      }
      &:hover{
        background: #3A91CC !important;
        color: white !important;
      }
    }
  }
}
// .highlight {
//   padding: 5px;
//   transition: all 0.2s linear;
// }

html {
  scroll-behavior: smooth;
}
// .isTourActive{
//   .form_input_wrp , .intro_active_connect{
//     padding: 8px;
//   }
// }

.shepherd-modal-overlay-container {
  z-index: 99 !important;
}

.addon_warning {
  background: #afddfb61;
  margin-bottom: 30px;
  border-radius: 8px;
  padding: 12px 12px 12px 85px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  @media (max-width:767px) {
    padding: 20px;
  }
  &::after{
    content: '';
    width: 220px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: #82b1ff5c;
    border-radius: 150px 0 0 150px;
    z-index: -1;
    @media (max-width:767px) {
      height: 50%;
      border-radius: 200px 200px 0 0;
      bottom: 0;
      left: 0;
      top: initial;
      width: 100%;
    }
  }
  .addon_warning_icon {
    position: absolute;
    left: 20px;
    top: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 60px;
    background: #4751da30;
    border-radius: 100%;
    transform: translateY(-50%);
    @media (max-width:767px) {
      position: relative;
      left: 0;
      top: 0;
      transform: none;
      margin: 0 auto 15px auto;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
      transform: scale(1.25);
    }
    span {
      font-size: 24px;
      color: #4751da;
    }
    svg{
      width: 100%;
      height: 100%;
      padding: 12px;
      color: #4751da;
    }
  }
  .addon_warning_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @media (max-width:767px) {
      display: block;
    }
    .left_sec {
      padding-right: 30px;
      width: calc(100% - 200px);
      @media (max-width:767px) {
        padding: 0 0 15px 0;
        width: 100%;
        text-align: center;
      }
      h2 {
        line-height: normal;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        color: #262C6C;
      }
      p {
        margin: 0;
        font-size: 13px;
        line-height: normal;
        a {
          font-weight: 500;
        }
      }
    }
    .right_sec {
      width: 200px;
      text-align: center;
      @media (max-width:767px) {
        width: auto;
        .btn_primary{
          width: 100%;
        }
      }
    }
  }
}

.auth_con_guide {
  .ant-modal-header {
    padding: 30px 30px 20px 30px;

    .ant-modal-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: @primary600;
    }
  }

  .ant-modal-close {
    top: 15px;

    .ant-modal-close-x {
      color: @primary600;
    }
  }

  .ant-modal-body {
    padding: 20px 30px 30px 30px;

    .auth_con_content {
      p {
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #000000d9;
        position: relative;
        padding-left: 25px;

        &:before {
          content: "";
          width: 10px;
          height: 10px;
          background: @3A91CC;
          position: absolute;
          left: 0;
          top: 4px;
        }
      }
    }

    .auth_con_content_btn {
      border-radius: 12px;
      background: @white;
      box-shadow: 0px 4px 8px 3px rgb(35 44 137 / 15%);
      padding: 15px;

      .ant-btn {
        width: 100%;
        background: @secondary700;
        border-radius: 100px;
        border: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: @white;
        height: 48px;
        cursor: pointer;
        transition: all 0.2s linear;

        &[disabled] {
          background: @BABAD7;
          border-color: @BABAD7;

          &:hover {
            background: @BABAD7;
            border-color: @BABAD7;
          }
        }

        &:hover {
          background-color: @3A91CC;
        }
      }
    }
  }
}


// Start guide

.joyride-step__holder{
  font-family: "Poppins" !important;
}

.joyride-backdrop.backdrop-left {
  position: relative;
  left: -5px;
}

.joyride-backdrop.backdrop-right {
  position: relative;
  left: 5px;
}
// .joyride-backdrop.backdrop-top {
// position: relative;
// top: -5px;
// }

// .joyride-backdrop.backdrop-bottom {
// position: relative;
// top: 5px;
// }

// .joyride-backdrop.backdrop-right:before, .joyride-backdrop.backdrop-left:before {
// content: '';
// background-color: rgba(59, 85, 96, 0.7);
// width: 100%;
// height: 5px;
// position: absolute;
// top: -5px;
// }


.backdrop-target {
  position: relative;
  &::after{
    content: '';
    height: 5px;
    background: white;
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
  }
  &::before{
    content: '';
    height: 5px;
    background: white;
    position: absolute;
    bottom: -5px;
    left: -5px;
    right: -5px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
  }
}


#dash_s1 [joyridestep="dash_s1"], #dash_s2 [joyridestep="dash_s2"] {
  position: relative;
  z-index: 1001;
}
#dash_s2 [joyridestep="dash_s2"].active_dash_s2 {
  z-index: initial;
}
//--
.joyride-backdrop.backdrop-top,
.joyride-backdrop.backdrop-bottom,
.joyride-backdrop.backdrop-left,
.joyride-backdrop.backdrop-right{
  background-color: rgb(0 0 0 / 30%) !important;
}

.step_content_wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 250px;
  .icon_sec {
    width: 70px;
    height: 70px;
    img {
      width: 100%;
      height:100%;
      object-fit: contain;
    }
  }
  .detail_sec {
    width: calc(100% - 70px);
    padding-left: 10px;
    p {
      font-size: 13px;
      line-height: 20px;
      margin: 0;
    }
    .btn_primary_outline {
      margin: 10px 0 0 0;
      padding: 7px 15px 4px 15px;
      font-size: 13px;
    }
  }
}


#joyride-step-dash_s1, #joyride-step-dash_s2, #joyride-step-thiredStep{
  .joyride-step__container{
    box-shadow: none;
    border-radius: 8px;
    .joyride-step__close{
      display: none;
    }
    .joyride-step__header{
      display: none;
    }
    .joyride-step__footer{
      display: none;
    }
    .joyride-step__body{
      background: white;
      border-radius: 10px;
      padding: 20px;
    }
  }
  joyride-arrow{
    display:none;
  }
}
#joyride-step-thiredStep{
  .joyride-step__container{
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    background: transparent;
  }
  &::after{
    content: '';
    width: 3px;
    height: 100%;
    border-right: 2px dashed white;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
  &::before{
    content: '';
    width: 10px;
    height: 10px;
    background: white;
    position: absolute;
    bottom: 0;
    left: calc(50% + 1px);
    transform: translateX(-50%);
    border-radius: 100%;
  }
}
#joyride-step-dash_s1{
  .joyride-step__container{
    padding-bottom: 70px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    background: transparent;
  }
  &::after{
    content: '';
    width: 3px;
    height: 100%;
    border-right: 2px dashed white;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
  &::before{
    content: '';
    width: 10px;
    height: 10px;
    background: white;
    position: absolute;
    bottom: 0;
    left: calc(50% + 1px);
    transform: translateX(-50%);
    border-radius: 100%;
  }
}

#joyride-step-dash_s2{
  .joyride-step__container{
    padding-bottom: 0px;
    padding-left: 70px;
    padding-right: 0;
    padding-top: 0;
    background: transparent;
  }
  &::after{
    content: '';
    width: 70px;
    height: 3px;
    border-top: 2px dashed white;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
  &::before{
    content: '';
    width: 10px;
    height: 10px;
    background: white;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
  }
}
#dash_s2 #backdrop-dash_s2   {
  z-index: 98 !important;
}

//--
#joyride-step-post_team, #joyride-step-post_postTo, #joyride-step-post_description, #joyride-step-post_schedule {
  .joyride-step__arrow {
    display: none;
  }
  .joyride-step__container {
    box-shadow: none;
    border-radius: 10px;
    padding: 20px;
    margin-top: 50px;
    .joyride-step__footer{
      .joyride-step__buttons-container {
        .joyride-step__button {
          .joyride-button {
            padding: 5px 15px 4px 15px;
            border-radius: 30px;
            border-width: 1px;
            background: transparent !important;
            border-color: #423FD1 !important;
            color: #423FD1 !important;
            transition: all 0.2s linear;
            &:hover{
              background: #423FD1 !important;
              color: white !important;
            }
          }
        }
      }
    }
    .joyride-step__body {
      padding: 0 0 15px 0;
    }
    .joyride-step__close {
      display: none;
    }
    .joyride-step__header {
      display: none;
    }
  }
}

#joyride-step-post_schedule{
  .joyride-step__container {
    margin-top: 0px;
    &::after{
      content: '';
      width: 100px;
      height: 130px;
      border: 3px dashed #fff;
      position: absolute;
      right: -100px;
      border-left: none;
      border-bottom: none;
      border-top-right-radius: 8px;
      top: 50%;
      transform: translateX(-50%);
    }
    &::before{
      content: '';
      width: 10px;
      height: 10px;
      background: white;
      position: absolute;
      right: -53px;
      border-radius: 100%;
      bottom: 0px;
    }
  }
}

#joyride-step-post_description{
  .joyride-step__container {
    margin-top: 0px;
    margin-left: 50px;
  }
  &::before{
    content: '';
    width: 50px;
    height: 3px;
    border-top: 2px dashed white;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
  &::after{
    content: '';
    width: 10px;
    height: 10px;
    background: white;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50% );
    border-radius: 100%;
  }
}

#joyride-step-post_team, #joyride-step-post_postTo{
  .joyride-step__container{
    &::before{
      content: '';
      width: 3px;
      height: 50px;
      border-left: 2px dashed white;
      position: absolute;
      top: -50px;
      z-index: -1;
      left: 50%;
      transform: translateX(-50%);
    }
    &::after{
      content: '';
      width: 10px;
      height: 10px;
      background: white;
      position: absolute;
      left: 50%;
      top: -50px;
      transform: translateX(-50%);
      border-radius: 100%;
    }
  }
}

#startSnozing{
  .backdrop-container, .joyride-step__holder {
    display: none;
  }
}

.subscribe_modal {
  width: 1000px !important;

  .ant-modal-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: @primary600;
    margin: 0;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .ant-modal-header {
    padding: 30px 30px 20px 30px;
  }

  .ant-modal-close {
    top: 13px;
  }

  .ant-modal-footer {
    padding: 20px 30px 20px 30px;

    // @media (max-width:1440px) {
    //     padding: 20px 30px 20px 30px;
    // }

    .ant-btn {
      background: @secondary700;
      border-color: @secondary700;
      height: auto;
      padding: 10px 25px 8px 25px;
      font-weight: 600;
      border-radius: 100px;

      &[disabled] {
        background: @BABAD7;
        color: @white;
        border-color: @BABAD7;

        &:hover {
          background: @BABAD7;
          border-color: @BABAD7;
        }
      }

      &:hover {
        background: @primaryColor;
        border-color: @primaryColor;
      }
    }
  }
}

.ready-to-upload-img{
  width: 150px !important;
  height: 150px !important;

}

.ant-badge-count{
    background: #EB0014;
}
