/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import './assets/less/variables.less';

//---Custome color

@secondary-color: #121f2b;
@dark-grey: #97abbf;
@light-grey: #6884a0;
@red: red;
@light-red: #f57275;
@light-blue: #eff4fa;

// -------- Colors -----------
@primary-color: #002244;
@info-color: @primary-color;
@success-color: @green-6;
@processing-color: @blue-6;
@error-color: @red-5;
@highlight-color: @red-5;
@warning-color: @gold-6;
@normal-color: #d9d9d9;
@white: #fff;
@black: #000;
@twitter: #00acee;
@linkedin: #3477b5;
@facebook: #3776e8;

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-Bold.woff2") format("woff2"),
  url("/assets/fonts/Poppins-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-ThinItalic.woff2") format("woff2"),
  url("/assets/fonts/Poppins-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-ExtraLightItalic.woff2") format("woff2"),
  url("/assets/fonts/Poppins-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-ExtraBold.woff2") format("woff2"),
  url("/assets/fonts/Poppins-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-Black.woff2") format("woff2"),
  url("/assets/fonts/Poppins-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-ExtraLight.woff2") format("woff2"),
  url("/assets/fonts/Poppins-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-ExtraBoldItalic.woff2") format("woff2"),
  url("/assets/fonts/Poppins-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-BlackItalic.woff2") format("woff2"),
  url("/assets/fonts/Poppins-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-BoldItalic.woff2") format("woff2"),
  url("/assets/fonts/Poppins-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-Thin.woff2") format("woff2"),
  url("/assets/fonts/Poppins-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-LightItalic.woff2") format("woff2"),
  url("/assets/fonts/Poppins-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-Medium.woff2") format("woff2"),
  url("/assets/fonts/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-Light.woff2") format("woff2"),
  url("/assets/fonts/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-Regular.woff2") format("woff2"),
  url("/assets/fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-SemiBoldItalic.woff2") format("woff2"),
  url("/assets/fonts/Poppins-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-Italic.woff2") format("woff2"),
  url("/assets/fonts/Poppins-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-MediumItalic.woff2") format("woff2"),
  url("/assets/fonts/Poppins-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
  url("/assets/fonts/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tungsten Rounded";
  src: local("Tungsten Rounded Bold"), local("Tungsten-Rounded-Bold"),
  url("/assets/fonts/TungstenRounded-Bold.woff2") format("woff2"),
  url("/assets/fonts/TungstenRounded-Bold.woff") format("woff"),
  url("/assets/fonts/TungstenRounded-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Tungsten Rounded";
  src: local("Tungsten Rounded Light"), local("Tungsten-Rounded-Light"),
  url("/assets/fonts/TungstenRounded-Light.woff2") format("woff2"),
  url("/assets/fonts/TungstenRounded-Light.woff") format("woff"),
  url("/assets/fonts/TungstenRounded-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Heebo';
  src: url('/assets/fonts/Heebo-Bold.woff2') format('woff2'),
      url('/assets/fonts/Heebo-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.text-57 {
  font-size: 57px;
  line-height: 64px;
  letter-spacing: 0px;
  font-weight: 700;
}

.text-45 {
  font-size: 45px;
  line-height: 52px;
  letter-spacing: 0px;
  font-weight: 700;
}

.text-36 {
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0px;
  font-weight: 600;
}

.text-32 {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0px;
  font-weight: 600;
}

.text-28 {
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0px;
  font-weight: 600;
}

.text-24 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0px;
  font-weight: 600;
}

.text-22 {
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0px;
  font-weight: 600;
}

.text-20 {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0px;
  font-weight: 600;
}

.text-16 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.text-14 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.text-12 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.text-16-bold {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.text-14-bold {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  font-weight: 600;
}



body {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-weight: 400;
  font-family: "Poppins";
  color: @1D203C;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins";
}

a {
  color: @494ADF;
  transition: all 0.2s linear;

  &:hover {
    color: @3A91CC;
  }
}

.btn_primary {
  border: 1px solid @secondary700;
  background-color: @secondary700;
  color: @white;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 23px 8px 23px;
  display: inline-block;
  transition: all 0.2s linear;
  cursor: pointer;
  &:hover {
    background-color: #423fd1cc;
    border-color: #423fd1cc;
    color: @white;
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
}
.btn_danger {
  background-color: #eb334338;
  color: #eb3343;
  border-color: #eb334338;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 23px 8px 23px;
  display: inline-block;
  transition: all 0.2s linear;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: #eb3343;
    border-color: #eb3343;
    color: @white;
  }
}
.btn_secondary {
  border: 1px solid @F6F7FF;
  background-color: @F6F7FF;
  color: @494ADF;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 23px 8px 23px;
  display: inline-block;
  transition: all 0.2s linear;
  cursor: pointer;
  &:hover {
    background-color: #423fd1cc;
    border-color: #423fd1cc;
    color: @white;
  }
}
.btn_primary_outline {
  border: 1px solid @secondary700;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 23px 8px 23px;
  display: inline-block;
  margin-right: 10px;
  transition: all 0.2s linear;
  color: @secondary700;
  background-color: @white;
  cursor: pointer;
  &:hover {
    background-color: @secondary700;
    border-color: @secondary700;
    color: @white;
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
}
.btn_secondary_outline {
  border: 1px solid @error-color;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 23px 8px 23px;
  display: inline-block;
  margin-right: 10px;
  transition: all 0.2s linear;
  color: @error-color;
  background-color: @white;
  cursor: pointer;
  &:hover {
    background-color: @error-color;
    border-color: @error-color;
    color: @white;
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
}
// ::ng-deep .cdk-virtual-scroll-viewport {
//   height: fit-content !important;
//   max-height: initial !important;
//   min-height: 200px;
// }
